var CookieAlert = {
    defines: {divID: "CookieAlert", cookieName: "agreeCookies", cookieValue: "yes", cookieExpire: 3},
    options: {
        style: "dark",
        position: "bottom",
        opacity: 1,
        displayTime: 0,
        text: 'Na stronie używamy plików cookies w celu ułatwienia korzystania ze strony, tworzenia statystyk, dostosowywania reklam do zainteresowań. Jeśli się nie zgadzasz na cookies, zmień ustawienia przeglądarki. <a style="color: rgba(255, 255, 255, 0.78)" href="http://beectrl.a2hosted.com/default/content/3-polityka-prywatnosci">Więcej informacji...</a>',
        cookiePolicy: ""
    },
    setCookie: function (e, o, i) {
        document.cookie = e + "=" + escape(o) + (null === i ? "" : "; expires=" + i.toGMTString()) + "; path=/"
    },
    checkCookie: function (e) {
        if ("" !== document.cookie) {
            var o = document.cookie.split("; ");
            for (i = 0; i < o.length; i++) {
                var t = o[i].split("=")[0], n = o[i].split("=")[1];
                if (t == e)return unescape(n)
            }
        }
    },
    removeDiv: function (e) {
        e.parentNode.removeChild(e);
        var i = new Date;
        i.setMonth(i.getMonth() + this.defines.cookieExpire), this.setCookie(this.defines.cookieName, this.defines.cookieValue, i);
    },
    fadeOut: function (e, o) {
        div = document.getElementById(o), div.style.opacity = e / 100, div.style.filter = "alpha(opacity=" + e + ")", 1 == e && (div.style.display = "none", done = !0)
    },
    init: function (e) {
        var o = CookieAlert;
        window.onload = function () {
            for (var i in e)o.options[i] = e[i];
            var t = document.getElementById(o.defines.divID);
            if(t !== null){
                t.innerHtml = '';
                if (o.checkCookie(o.defines.cookieName) === o.defines.cookieValue) {
                    o.removeDiv(t);
                }
                else{
                    t.style.opacity = '1';
                    document.getElementById("CookieAlertClose").addEventListener("click", function () {
                        o.removeDiv(t);
                    });
                }
            }
        }
    }
};
CookieAlert.init();