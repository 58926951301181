jQuery(document).ready(function () {

    var elements = $("INPUT");
    for (var i = 0; i < elements.length; i++) {

        if(elements[i].type.toLowerCase() == 'password') {
            elements[i].setAttribute('pattern','(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{5,}');
            elements[i].oninvalid = function(e) {
                e.target.setCustomValidity("");
                if (!e.target.validity.valid) {
                    e.target.setCustomValidity('Hasło o długości minimum 5 znaków musi zawierać jedną liczbę oraz jedną wielką i małą literę.');
                }
            };
            elements[i].oninput = function(e) {
                e.target.setCustomValidity("");
            };
        }
    }
});