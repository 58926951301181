function initPdfPrinter(){
    $('.downloadPdfCart').on('click', function (e) {
        e.preventDefault();
        var productWrapper = $('#content-wrapper');
        var productName = productWrapper.find('[itemprop=name]');
        var productPrice = productWrapper.find('[itemprop=price]');
        var productFeatures = productWrapper.find('.features');
        var productDescription = productWrapper.find('.product-description');
        var productSizeTable = document.getElementsByClassName("sizeTable")[0].getElementsByTagName('tr');
        var productImage = getBase64Image(document.querySelector('#MagicThumbImageMainImage img'));

        var topBannerLeft = getBase64Image(document.querySelector('.header-middle .leftEdge'));
        var topBannerMiddle = getBase64Image(document.querySelector('.header-middle .middle'));
        var topBannerRight = getBase64Image(document.querySelector('.header-middle .rightEdge'));

        var productFeaturesArray = [];
        var productDescriptionArray = [];
        var productSizeTableArray = [];
        productFeatures.find('span').each(
            function () {
                productFeaturesArray.push($(this).text());
            }
        );
        productDescription.find('li').each(
            function () {
                productDescriptionArray.push($(this).text());
            }
        );

        var productSizeTableLength = productSizeTable.length;

        for (var i = 0; i < productSizeTableLength; i++) {
            productSizeTableArray[i] = [];
            var elements = productSizeTable[i].querySelectorAll("td,th");
            var elementsLength = elements.length;
            var tempArray = [];
            for (var j = 0; j < elementsLength - 1; j++) {
                productSizeTableArray[i][j] = elements[j].innerHTML;
            }
        }
        var docDefinition = {
            content: [
                {
                    style: 'tableExample',
                    margin: [0, 10, 0, 10],
                    table: {
                        widths: [150, 180, 130],
                        body: [
                            [
                                {
                                    image: 'data:image/jpeg;base64,' + topBannerLeft,
                                    width: 130,
                                    margin: [0, 10, 0, 0]
                                },
                                {
                                    image: 'data:image/jpeg;base64,' + topBannerMiddle,
                                    width: 160,
                                    margin: [0, 10, 0, 0]
                                },
                                {
                                    image: 'data:image/jpeg;base64,' + topBannerRight,
                                    width: 120
                                }]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    image: 'data:image/jpeg;base64,' + productImage,
                    width: 400,
                    alignment: 'center'
                },
                {text: productName.text(), style: 'header'},

                {text: productPrice.text(), style: 'price'},
                {
                    type: 'none',
                    ul: productFeaturesArray
                },
                {text: 'Stany magazynowe', style: 'header_style_2'},
                {
                    headerRows: 2,
                    style: 'tableExample',
                    table: {
                        widths: [120, 120, 120],
                        body: productSizeTableArray
                    }
                },
                {text: 'Opis', style: 'header_style_2'},
                {
                    type: 'none',
                    style: 'description',
                    ul: productDescriptionArray
                },
            ],

            styles: {
                header: {
                    fontSize: 22,
                    bold: true,
                    margin: [0, 20, 0, 8]
                },
                price: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 20, 0, 8]
                },
                description: {
                    fontSize: 12,
                    margin: [0, 5, 0, 8]
                },
                header_style_2: {
                    fontSize: 14,
                    margin: [0, 20, 0, 8]
                }
            }
        };

// download the PDF
        pdfMake.createPdf(docDefinition).download(productName.text()+'.pdf');

    });
}
initPdfPrinter();
function getBase64Image(img) {
    // Create an empty canvas element
    var canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    var dataURL = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}