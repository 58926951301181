function setActiveTabs() {
    $('.checkoutTabs__tab').each(function () {
        $(this).removeClass('active')
        if ($('#' + $(this).data('id')).hasClass('-current') || $('#' + $(this).data('id')).hasClass('-complete')) {
            $(this).addClass('active')
        }
    });
}
setActiveTabs();
$('.checkout-step').on('click', function (e) {
    setTimeout(function () {
        setActiveTabs()
    }, 50)
})