$("input[type=number").on("input", function (e) {
    console.log($(this).attr('max'), $(this).val() > $(this).attr('max'));
    if ($(this).attr('max') > 0 && parseInt($(this).val()) > parseInt($(this).attr('max'))) {
        $(this).val($(this).attr('max'));
    }
    else {
        console.log('$(this).val().indexOf("-")', $(this).val(), $(this).val().indexOf("-"));

        if (~$(this).val().indexOf("-") || !$(this).val()) {
            $(this).val($(this).attr('min'));
        }
    }
});