var modalLink = $('.promoMovies__list__item');
modalLink.on('click', function (e) {
    e.preventDefault();
    $(this).next().show();
    $(this).next().find('video').get(0).play();
    console.log($(this).next().find('video').get(0).videoHeight);
});
modalLink.next().find('video').on('playing', function (e) {
    $(this).show();
});
modalLink.each(function () {
    modalLink.next().find('.closeBtn').on('click', function (e) {
        $(this).parent().hide();
        $(this).next().find('video').get(0).pause();
        $(this).next().find('video').get(0).currentTime = 0;
    })
})