function bulkAddListener() {
    var bulkForm = $('#add-to-cart-or-refresh');
    var addButton = bulkForm.find('button[data-button-action="add-to-cart-bulk"]');
    var combinationRows = bulkForm.find('.sizeTable__body__row');
    addButton.on('click', function (e) {
        e.preventDefault();
        var flag = false;
        combinationRows.each(function(){
            if($(this).find('[data-type="qty"] input').val() !== 0){
                flag = true;
            }
            console.log(flag);
        });
        if(flag){
            combinationRows.each(function () {
                var quantity = ($(this).find('[data-type="qty"] input').val() > 0) ? $(this).find('[data-type="qty"] input').val() : 0;
                $.ajax({
                    type: 'POST',
                    headers: {"cache-control": "no-cache"},
                    url: $(this).data('add_to_cart_url'),
                    async: true,
                    cache: false,
                    dataType: "json",
                    data: 'qty=' + quantity,
                    success: function (data) {
                        $('.opc-main-block, .step-num, #HOOK_SHOPPING_CART_EXTRA').fadeOut('fast');
                        $('#order-detail-content').fadeOut('fast', function () {
                            $('#emptyCartWarning').fadeIn('slow');
                        });
                        ajaxCart.refresh();
                    }
                })
            });
            $(document).ajaxStop(
                function () {
                    bulkForm.submit();
                }
            );
        }
    })
};
bulkAddListener();