jQuery(document).ready(function ($) {

    createMagicList(".magic-list");

    function createMagicList(element) {
        setTimeout(function () {
        var magicLists = $(element);

            magicLists.each(function (index, elem) {
                if (!$(elem).hasClass('initialized')) {
                    $(elem).addClass("initialized");
                    var elemHeight = $(elem).height();
                    if (elemHeight > 100) {
                        var liH = $(elem)
                            .find("li:first")
                            .outerHeight(true);

                        $(elem).addClass("collapsed");

                        $(elem).css({
                            "max-height": liH * 4 + "px"
                        });

                        var link = $("<a>");
                        link.attr("href", "#"+ $(elem).attr("id"));
                        link.text("[ rozwiń listę ]");
                        link.addClass("custom-toggler");
                        $(elem).after(link);
                        link.on("click", function (e) {
                            e.preventDefault();
                            var target = $($(this).attr("href"));
                            if (target.hasClass("collapsed")) {
                                target.removeClass("collapsed");
                                target.addClass("expanded");
                                $(this).text("[ zwiń listę ]");
                            } else {
                                target.removeClass("expanded");
                                target.addClass("collapsed");
                                $(this).text("[ rozwiń listę ]");
                            }
                        });
                    }
                }
            });
            createMagicList(element);
        }, 20);

    }

});