var dropArea, wrapperClass, openModal, overlapThreshold;


function initModal() {
    dropArea = $("#dropArea");
    wrapperClass = 'logoContainerWrapper';
    openModal = document.getElementsByClassName('openGsapModal');
    overlapThreshold = "99%";
    $('body > #visualisationModal').remove();

    for (var i = 0; i < openModal.length; i++) {
        openModal.item(i).addEventListener("click", function () {
            $('#' + $(this).data('modal')).appendTo('body');
            TweenMax.fromTo('#' + $(this).data('modal'), 0.25, {autoAlpha: 0}, {autoAlpha: 1}, 0.001);
            $('body').addClass('disableScroll');
        })
    }


    $("#show_button").click(function () {
        $('.focused').removeClass('focused');
        html2canvas($('#dropArea'), {
            onrendered: function (canvas) {
                var a = document.createElement('a');
                // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
                a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
                a.download = 'wizualizacja.jpg';
                a.click();
            }
        });
    });
    $('.resize-up').click(function () {
        var focused = $('.focused img');
        focused.css({'height': focused.innerHeight() + 10 + "px"});
    });
    $('.resize-down').click(function () {
        var focused = $('.focused img');
        focused.css({'height': focused.innerHeight() - 10 + "px"});
    });
    $('.remove').click(function () {
        var focused = $('.focused img');
        focused.remove();
    });
    $('.gsapModal__button--close').click(function () {
        TweenMax.fromTo($(this).closest('.gsapModal'), 0.25, {autoAlpha: 1}, {autoAlpha: 0}, 0.001);
        $('body').removeClass('disableScroll');
    })
}
function createDraggable(droppables) {
    Draggable.create(droppables, {
        bounds: window,
        onDrag: function (e) {
            $('.focused').removeClass('focused');
            $(this.target).parent().addClass("focused");
            if (this.hitTest(dropArea, overlapThreshold)) {
                $(this.target).addClass("inZone");
            } else {
                $(this.target).removeClass("inZone");
            }
        },
        onDragEnd: function (e) {
            //instead of doing hitTest again, just see if it has the highligh class.
            //if there isn't a highlight, send it back to starting position
            if (!$(this.target).hasClass("inZone")) {
                //if there isn't a highlight, send it back to starting position
                TweenLite.to(this.target, 0.2, {
                    x: 0,
                    y: 0
                });
            }
        }
    });

    TweenLite.to(droppables, 0, {
        x: 0,
        y: 0
    });
}

function createImg(src, alt, title) {
    var img = document.createElement('img');
    var div = document.createElement('div');
    var divWrapper = document.createElement('div');
    div.className = 'logoContainer';
    divWrapper.className = wrapperClass;
    divWrapper.append(div);
    img.src = src;
    if (alt != null) img.alt = alt;
    if (title != null) img.title = title;
    div.appendChild(img);
    return divWrapper;
};
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        var container = document.getElementById('dropArea');
        reader.onload = function (e) {
            var image = createImg(e.target.result, 'alt', 'title');
            container.appendChild(image);
            $('.focused').removeClass('focused');
            $(image.getElementsByClassName('logoContainer')).parent().addClass("focused");
            createDraggable(image.getElementsByClassName('logoContainer'));
        }
        reader.readAsDataURL(input.files[0]);
        input.value = '';
    }
}
initModal();